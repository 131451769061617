import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tabs",
      "style": {
        "position": "relative"
      }
    }}>{`Tabs`}</h1>
    <p>{`Tabs are used to quickly navigate between views within the same context, allowing a user to access different contents without leaving the page.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Labels are concise and use one to two words maximum.`}</li>
      <li parentName="ul">{`Use Tabs when the content and structure of the pages are different.`}</li>
      <li parentName="ul">{`A maximum of six tabs may be displayed. This is to maintain an uncluttered UI and reduce cognitive load for users.`}</li>
      <li parentName="ul">{`Tabs with related content should be grouped adjacent to each other.`}</li>
      <li parentName="ul">{`It's not recommended to use Tabs as filters.`}</li>
    </ul>
    <h2 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}>{`Examples`}</h2>
    <Demo src='pages/components/tabs/Tab' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      